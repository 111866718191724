<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <div v-if="!(userData && roles)" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>

    <b-tabs
      v-if="userData && roles"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Akun</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          :roles="roles"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab v-if="userData.id !== null">
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Password</span>
        </template>
        <user-edit-tab-social :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import router from '@/router'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabSocial from './UserEditTabPassword.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    UserEditTabAccount,
    UserEditTabSocial,
  },
  data() {
    return {
      userData: null,
      roles: null,
      // companies: null,
    }
  },
  methods: {
    fetchRoles() {
      this.$http.get('/admin/v1/roles')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.roles = []

            currentData.forEach((item, index) => {
              this.roles.push({
                label: item.name,
                value: item.name
              })
            })
            
          }
      })
    },

    fetchUserData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/users/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.userData = {
              id: currentData.id,
              name: currentData.name,
              email: currentData.email,
              phone: currentData.phone,
              church_jemaat_id: currentData.church_jemaat_id,
              church_name: currentData.church_name,
              address: currentData.address,
              photo: currentData.photo,
              photo_blob: null,
              active: (currentData.email_verified_at !== null),
              role: (currentData.roles !== null && currentData.roles.length > 0) ? currentData.roles[0].name : null,
            }
          
          }
      })
    }
  },
  created() {
    this.fetchUserData()
    this.fetchRoles()
    // this.fetchCompanies()
    // this.fetchCities()
  },
}
</script>
